import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import adminService from "../../../Services/admin.service";
import PopupModal from "../../General/PopupModal/PopupModal";
import "./UpdateUserProductModal.scss";

export default function UpdateUserProductModal(props) {    
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const [data, setData] = useState();
    const [resiData, setResiData] = useState();
    const [loading, setLoading] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (props.id) {
            adminService.getUser(props.id).then((data) => {
                setData(data);

                var resiOrder = data.activeOrders.find(x => x.productId == "86da73c4-47cc-43cd-a291-b61ec25d33d9");

                if (resiOrder)
                    adminService.getResiData(resiOrder.properties["id"]).then((data) => {                        
                        setResiData(data);
                    });

                setLoading(false);
            });
        }
    }, []);


    const onSubmit = (data) => {
        // if (data.quantity < resiData.traffic) {
        //     alert("Quantity can't be less than the traffic (" + resiData.traffic + "GB)");
        //     return;
        // }

        setLoading(true);

        adminService.updateProductToUser(data).then((result) => {
            setShowSuccess(true);
            props.successCallback();
        }).catch((error) => {
            setError(error.response.data.title);
        }).finally(() => {
            setLoading(false);
        })
    };

    return (
        <PopupModal>
            <div className="add-product-to-user-modal">
                {showSuccess ?
                    <div className="modal-header">
                        <h3 className="modal-title">Product updated successfully</h3>
                    </div>
                :
                    <>
                        <div className="modal-header">
                            <h3 className="modal-title">Update Product</h3>
                        </div>
                        <div className="modal-body">
                            {resiData && 
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Controller name="accountId" control={control} defaultValue={props.id} render={({ field }) => <input {...field} hidden></input>} />
                                    <Controller name="productId" control={control} defaultValue={props.productId} render={({ field }) => <input {...field} hidden></input>} />

                                    <div className="form-item w-50">
                                        <label>GB</label>
                                        <Controller
                                            name="quantity"
                                            control={control}
                                            defaultValue={resiData.trafficLimit}
                                            required={true}
                                            render={({ field }) => <input {...field} type="number" required placeholder='Enter GB' />}
                                        />
                                    </div>
                                    
                                    {
                                        error && 
                                            <p className="error">{error}</p>
                                    }
                                    <button id="submit-form" type="submit" disabled={loading}>
                                        {loading && <div className="loader"></div>}
                                        Save
                                    </button>
                                </form>
                            }
                        </div>
                    </>
                }
            </div>
        </PopupModal>
    )
}