import React, { useEffect, useRef } from 'react';
import PopupModal from "../../General/PopupModal/PopupModal";
import "./ProxyCardModal.scss";
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import purchaseService from '../../../Services/purchase.service';
import { useModal } from '../../../Contexts/ModalContext';
import LoginModal from '../../Auth/LoginModal/LoginModal';
import { checkFreeProxies } from '../../../Actions/auth';


function ProxyCardModal(props) {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const isHomepage = window.location.pathname === '/';
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const { openModal } = useModal();

    const { isLoggedIn } = useSelector(state => state.auth);

    const modalRef = useRef(null);


    useEffect(() => {
        if(user) 
            dispatch(checkFreeProxies());
    }, []);

    const onAfterOpen = () => {
        const card = document.getElementById(props.value.id);
        let bounds = card?.getBoundingClientRect();
        if (modalRef.current.node.children[0] && isHomepage && bounds.top > 0 && bounds.bottom < window.innerHeight) {
            modalRef.current.node.children[0].children[0].style.position = 'fixed';
            modalRef.current.node.children[0].children[0].style.top = `${bounds.top}px`;
            modalRef.current.node.children[0].children[0].style.left = `${bounds.left}px`;
            modalRef.current.node.children[0].children[0].style.width = `${bounds.width}px`;
            modalRef.current.node.children[0].children[0].style.height = `${bounds.height}px`;
            modalRef.current.node.children[0].children[0].style.margin = `0px`;
        }
    }

    const handlePurchase = () => {
        setLoading(true);

        const request = { trial: true };

        if (!isLoggedIn) {
            openModal(LoginModal, request);
            setLoading(false);
        } else {
            purchaseService.purchaseTrial().then((data) => {
                window.location.href = data.url;
            }).catch((error) => {
                setError(error.response.data.message);
                dispatch(checkFreeProxies());
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <PopupModal modalRef={modalRef} onAfterOpen={onAfterOpen}>
            <div className="proxy-card proxy-card-modal" style={!isHomepage ? { maxWidth: '500px' } : {}}>
                <div className="proxy-card-header">
                    <div className="icon-container">
                        <img alt={props.value.name} src={require(`../../../assets/icons/${props.value.icon}`)} />
                    </div>
                    <span className="proxy-card-title">{props.value.name}</span>
                </div>
                <div className="proxy-card-body">
                    <div className="proxy-card-description">
                        Claiming 200MB in free residential data will automatically top up your account via our Dashboard. This will give you the opportunity to generate residential proxies in different regions and ensure they suit your use case.
                    </div>
                    <div className="proxy-card-description">
                        <i>Usage is monitored and abuse will lead to account suspension.</i>
                    </div>
                    <hr />
                    <button className="proxy-card-modal-body-button" onClick={() => handlePurchase()} disabled={isLoading}>
                        {(isLoading) && <div className="loader"></div>}
                        Claim Now
                    </button>
                    {
                        error && (
                            <div className="proxy-card-modal-body-error">
                                {error}
                            </div>
                        )
                    }
                </div>

                <div className="proxy-card-footer">
                    <span className="details-title">Features</span>
                    <div className="details-container">
                        {
                            props.value.details.keypoints.map((point) => (
                                <div key={point.title} className="details-row">
                                    <img src={require('../../../assets/icons/checkmark-2.svg').default} />
                                    <span>{point.title}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </PopupModal>
    )
}

export default ProxyCardModal;