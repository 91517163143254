import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from './Layouts/MainLayout/MainLayout';
import Home from './Pages/Home';
import { useEffect } from 'react';
import Aos from 'aos';
import { ModalProvider } from './Contexts/ModalContext';
import DashboardLayout from './Layouts/DashboardLayout/DashboardLayout';
import Residential from './Pages/Dashboard/Residential/Residential';
import DashboardHome from './Pages/Dashboard/DashboardHome/DashboardHome';
import Servers from './Pages/Dashboard/Servers/Servers';
import Mobile from './Pages/Dashboard/Mobile/Mobile';
import Isp from './Pages/Dashboard/Isp/Isp';
import { Analytics } from '@vercel/analytics/react';

import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, CategoryScale, Filler, Interaction, TimeScale } from 'chart.js';
import React from "react";

import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import Company from './Pages/Company/Company';
import ScrollToTop from './Components/General/ScrollToTop';
import Overview from './Pages/Admin/Overview/Overview';
import BlogManagement from './Pages/Admin/BlogCreator/BlogManagement';
import Blog from './Pages/Blog/Blog';
import BlogPost from './Pages/Blog/BlogPost/BlogPost';
import { Helmet } from 'react-helmet';
import IspAdmin from './Pages/Admin/IspAdmin/IspAdmin';
import Users from './Pages/Admin/Users/Users';
import ProductDetails from './Pages/ProductDetails/ProductDetails';
import ServersAdmin from './Pages/Admin/Servers/ServersAdmin';

import products from './data/products';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Tooltip, TimeScale);
ChartJS.register(CrosshairPlugin);

Interaction.modes.interpolate = Interpolate

function App() {

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <ModalProvider>
      <Helmet>
        <title>Primed Proxies - High-quality data collection infrastructure.</title>
        <meta name="description"
          content="High-quality data collection infrastructure for any application. Scale your public data gathering using a 45M+ IP proxy pool renowned for its top-tier quality."></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Primed Proxies - High-quality data collection infrastructure"></meta>
        <meta property="og:description"
          content="High-quality data collection infrastructure for any application. Scale your public data gathering using a 45M+ IP proxy pool renowned for its top-tier quality."></meta>
        <meta property="twitter:title" content="Primed Proxies - High-quality data collection infrastructure"></meta>
        <meta property="twitter:description"
          content="High-quality data collection infrastructure for any application. Scale your public data gathering using a 45M+ IP proxy pool renowned for its top-tier quality."></meta>
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path='residential' element={<ProductDetails product={products.proxies.find(x => x.path == 'residential')}/>} />
            <Route path='isp' element={<ProductDetails product={products.proxies.find(x => x.path == 'isp')}/>} />
            <Route path='servers' element={<ProductDetails product={products.servers[0]}/>} />
            <Route path='contact' element={<Home />} />
            <Route path='blog' element={<Blog />} />
            <Route path='blog/:path' element={<BlogPost />} />
            <Route path='terms-conditions' element={<Company title="Terms and Conditions" />} />
            <Route path='privacy-policy' element={<Company title="Privacy Policy" />} />
            <Route path='refund-policy' element={<Company title="Refund Policy" />} />
          </Route>
          <Route path='/dashboard' element={<DashboardLayout />}>
            <Route index element={<DashboardHome />} />
            <Route path='residential' element={<Residential />} />
            <Route path='isp' element={<Isp />} />
            <Route path='servers' element={<Servers />} />
            <Route path='admin'>
              <Route index element={<Overview />} />
              <Route path='blog' element={<BlogManagement />} />
              <Route path='isp' element={<IspAdmin />} />
              <Route path='users' element={<Users />} />
              <Route path='servers' element={<ServersAdmin />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <Analytics />
    </ModalProvider>
  );
}

export default App;
