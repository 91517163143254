import "./IspAdmin.scss";
import GenerateResult from "../../../Components/Dashboard/GenerateResult/GenerateResult";
import { useEffect, useState } from "react";
import adminService from "../../../Services/admin.service";
import UserModal from "../../../Components/Admin/UserModal/UserModal";
import Card from "../../../Components/General/Card/Card";
import { useDebouncedCallback } from "use-debounce";
import { useModal } from "../../../Contexts/ModalContext";

function IspAdmin() {

    const [proxies, setProxies] = useState([]);
    const [proxiesTableData, setProxiesTableData] = useState([]);
    const [stockData, setStockData] = useState([]);

    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);

    const { openModal } = useModal();

    const debounced = useDebouncedCallback((value) => {
        setSearchValue(value);
    }, 500);

    
    const handlePrevious = () => {
        let newPage = page - 1;
        setPage(newPage);
        adminService.getProxies(newPage, searchValue).then(data => setProxiesTableData(data));
    }

    const handleNext = () => {
        let newPage = page + 1;
        setPage(newPage);
        adminService.getProxies(newPage, searchValue).then(data => setProxiesTableData(data));
    }

    useEffect(() => {
        adminService.getAllIsps().then((result) =>{setProxies(result)});
        adminService.getProxies(page, searchValue).then((result) => setProxiesTableData(result));
        adminService.getIspStock(page, searchValue).then((result) => setStockData(result));
    }, []);

    useEffect(() => {
        setPage(1);
        adminService.getProxies(1, searchValue).then(data => setProxiesTableData(data));
    }, [searchValue]);

    return (
        <div className="dashboard-page isp-admin-page-container table-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">ALL</span>
            </div>

            <div className="stock-container">
                {stockData.map(stock => (
                    <Card classes="stock-item">
                        <div className="stock-server">
                            {stock.server}
                            <div className="tooltip-container">
                                <span className="tooltip-toggle">
                                    <img className="info-icon" src={require(`../../../assets/icons/info.svg`).default} />
                                </span>
                                <div className="tooltip-content-container">
                                    <div className="tooltip-content">
                                        <span className="tooltip-subnets-title">Subnets:</span>
                                        <ul className="tooltip-subnets">
                                            {stock.subnets.map(subnet => (
                                                <li key={subnet}>{subnet}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stock-available">Available: {stock.stock} / {stock.totalStock}</div>
                        {
                            stock.assigned && (
                                <div className="stock-assigned">
                                    Assigned: {stock.assigned.amount} {stock.assigned.totalProxies > 0 && `(${stock.assigned.totalProxies})`}
                                </div>
                            )
                        }
                    </Card>
                ))}
            </div>
            

            <div className="result-container">
            {proxiesTableData && 
                <Card classes="table-card mt-0">
                    <div className="search-container">
                        <input className="search-input" placeholder="Search..." onChange={(e) => debounced(e.target.value)}></input>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th>Id</th>
                                <th>Expiry date</th>
                                <th>Quantity</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>User id</th>
                                <th/>
                            </tr>
                            {
                                proxiesTableData.proxies && proxiesTableData.proxies.map(proxy => (
                                    <tr key={proxy.id}>
                                        <td>{proxy.id}</td>
                                        <td>{proxy.expiry}</td>
                                        <td>{proxy.properties.quantity}</td>
                                        <td>{proxy.properties.username}</td>
                                        <td>{proxy.properties.password}</td>
                                        <td><span className="clickable" onClick={() => openModal(UserModal, {id: proxy.userId})}>{proxy.userId}</span></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="nav-button-container">
                        {page > 1 && <button onClick={handlePrevious}>Previous</button>}
                        {proxiesTableData && page < proxiesTableData.totalPages && <button onClick={handleNext}>Next</button>}
                    </div>
                </Card>
            }
                <GenerateResult proxies={proxies} />
            </div>
        </div>
    )
}

export default IspAdmin;