export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLAIMED_FREE_PROXIES = "CLAIMED_FREE_PROXIES";

export const SET_PRICES = "SET_PRICES";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_PLANS = "SET_PLANS";
export const SET_RESIDATA = "SET_RESIDATA";
export const SET_PROXIES = "SET_PROXIES";
export const SET_ISPS = "SET_ISPS";
export const SET_SERVERDATA = "SET_SERVERDATA";
export const SET_MOBILE = "SET_MOBILE";

export const SET_DAILY_ANALYTICS = "SET_DAILY_ANALYTICS";
export const SET_MONTHLY_ANALYTICS = "SET_MONTHLY_ANALYTICS";

export const SET_ADMIN_ANALYTICS = "SET_ADMIN_ANALYTICS";