import { useEffect } from "react";
import DataCard from "../../../Components/General/DataCard/DataCard";
import "./Overview.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAnalytics } from "../../../Actions/admin";
import Card from "../../../Components/General/Card/Card";

function Overview() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminAnalytics());
    }, [])

    const { analytics } = useSelector(state => state.admin);

    return (
        <div className="dashboard-page overview-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">Analytics</span>
            </div>
            <div className="analytics-overview-container">
                {
                    analytics && <>
                        <DataCard title="Members" data={analytics.users} />
                        <DataCard title="Fulfilled Orders" data={analytics.fulfilled} />
                        <DataCard title="Unfulfilled Orders" data={analytics.unfulfilled} />
                        <DataCard title="Resi Usage (GB)" 
                            subTitle={`since ${analytics.usageSince}`}
                            data={Math.round(analytics.usage)?.toLocaleString('en-US')} 
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default Overview;