import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import logo from "../../assets/images/logo.png";
import logoReversed from "../../assets/images/logo-reversed.png";

import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import './MainLayout.scss';
import ModalRoot from "../../Components/General/PopupModal/ModalRoot";
import { useModal } from "../../Contexts/ModalContext";
import LoginModal from "../../Components/Auth/LoginModal/LoginModal";
import RegisterModal from "../../Components/Auth/RegisterModal/RegisterModal";
import { useDispatch, useSelector } from "react-redux";
import { getPrices } from "../../Actions/public";
import ResetModal from "../../Components/Auth/ResetModal/ResetModal";
import { logout } from "../../Actions/auth";

const links = [
    { page: "Home", id: "#" },
    { page: "Products", id: "#products" },
    { page: "Features", id: "#features" },
    { page: "Testimonials", id: "#reviews", classes: "hidden-md" },
    { page: "Contact", id: "#contact" },
    { page: "Blog", id: "blog" },
]

function MainLayout() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const { isLoggedIn } = useSelector(state => state.auth);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const navigate = useNavigate();
    const location = useLocation();
    const { openModal } = useModal();

    const handleOpenLoginModal = () => {
        if (isLoggedIn)
            navigate("/dashboard");
        else
            openModal(LoginModal);
    };

    const handleRegisterModal = () => {
        openModal(RegisterModal);
    };

    const handleSignout = () => {
        dispatch(logout());
    }

    const dispatch = useDispatch();

    useEffect(() => {
        // try { window.zE('messenger', 'show'); } catch { }
        dispatch(getPrices());
    }, []);


    useEffect(() => {
        let query = new URLSearchParams(location.search);

        let email = query.get('email');
        let token = query.get('token');

        if (email && token) {
            openModal(ResetModal, { email: email, token: token });
            navigate('/');
        }

    }, [location])

    return (
        <>
            <nav className="navbar-container">
                <ModalRoot />
                <div className="image-container" onClick={() => navigate("/")} >
                    <img src={logo} alt="Logo"/>
                </div>
                <ul id="links-web">
                    {
                        links.map(element => (
                            <li key={element.page} className={element.classes}>
                                <Link to={`/${element.id}`}>{element.page}</Link>
                            </li>
                        ))
                    }
                </ul>
                <div id="auth-web">
                    {
                        isLoggedIn ?
                            <>
                                <button onClick={handleSignout}>Sign out</button>
                                <button id="get-started" onClick={handleOpenLoginModal}>Dashboard</button>
                            </>
                            :
                            <>
                                <button onClick={handleOpenLoginModal}>Sign in</button>
                                <button id="get-started" onClick={handleRegisterModal}>Get started now</button>
                            </>
                    }
                </div>
                <button onClick={handleNavCollapse} className="toggle-icon">
                    <HamburgerIcon />
                </button>
            </nav>

            <div className={`${!isNavCollapsed ? 'open' : ''} mobile-nav`}>
                <div className="mobile-header">
                    <img src={logoReversed} alt="Logo" />
                    <button onClick={handleNavCollapse}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="mobile-body">
                    {
                        links.map(element => (
                            <li key={element.page} className={element.classes}>
                                <Link to={`/${element.id}`} onClick={handleNavCollapse}>{element.page}</Link>
                            </li>
                        ))
                    }
                    <hr />
                    {
                        isLoggedIn ?
                            <li>
                                <a onClick={handleOpenLoginModal}>Dashboard</a>
                            </li>
                            :
                            <li>
                                <a onClick={handleOpenLoginModal}>Login</a>
                            </li>
                    }
                </div>
                <div className="mobile-footer">
                    <a>Privacy Policy</a>
                    <a>Terms of Use</a>
                    <p>© {new Date().getFullYear()} Primed Project R Ltd</p>
                    <p>Registered at 42-44 Bishopsgate, London, United Kingdom, EC2N 4AH · Company no. 13043119</p>
                </div>
            </div>

            <Outlet />
        </>
    )
}

export default MainLayout;