import { useEffect, useState } from "react";
import Card from "../../../Components/General/Card/Card";
import "./Users.scss";
import adminService from "../../../Services/admin.service";
import { useDebouncedCallback } from "use-debounce";
import { useModal } from "../../../Contexts/ModalContext";
import UserModal from "../../../Components/Admin/UserModal/UserModal";

function Users() {

    const { openModal } = useModal();

    const [searchValue, setSearchValue] = useState("");
    const debounced = useDebouncedCallback((value) => {
        setSearchValue(value);
    }, 500);

    const [lastSearch, setLastSearch] = useState();
    const [data, setData] = useState();
    const [page, setPage] = useState(1);

    useEffect(() => {
        adminService.getUsers(page).then(data => setData(data));
    }, []);

    useEffect(() => {
        if (lastSearch || searchValue){
            setPage(1);
            adminService.getUsers(1, searchValue).then(data => setData(data));
            setLastSearch(searchValue);
        }
    }, [searchValue]);

    const handlePrevious = () => {
        let newPage = page - 1;
        setPage(newPage);
        adminService.getUsers(newPage, searchValue).then(data => setData(data));
    }

    const handleNext = () => {
        let newPage = page + 1;
        setPage(newPage);
        adminService.getUsers(newPage, searchValue).then(data => setData(data));
    }

    const showUser = (id) => {
        openModal(UserModal, {id: id});
    }

    return (
        <div className="dashboard-page table-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">Users</span>
            </div>
            <Card classes="table-card mt-20">
                <div className="search-container">
                    {data && <span>Total Users: {data.totalCount}</span>}
                    <input className="search-input" placeholder="Search..." onChange={(e) => debounced(e.target.value)}></input>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Stripe ID</th>
                            <th>Created On</th>
                            <th />
                        </tr>
                        {
                            data && data.users.map((user, i) => (
                                <tr key={`user-${i}`}>
                                    <td>{user.email}</td>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.stripeId}</td>
                                    <td>{user.createdOn}</td>
                                    <td><button onClick={() => showUser(user.id)}>View</button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="nav-button-container">
                    {page > 1 && <button onClick={handlePrevious}>Previous</button>}
                    {data && page < data.totalPages && <button onClick={handleNext}>Next</button>}
                </div>
            </Card>
        </div>
    )
}

export default Users;