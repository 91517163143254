var genning = [
    {
        id: 1,
        group: "Ticketing",
        children: [
            {
                id: 100,
                name: "Events GB",
                domain: "eventsgb.primedproxies.com",
                countryCodes: [
                    "GB"
                ]
            },
            {
                id: 101,
                name: "Events EU",
                domain: "eventseu.primedproxies.com",
                countryCodes: [
                    "FR",
                    "IT",
                    "NL",
                    "AT",
                    "BE",
                    "CH",
                    "DE",
                    "DK",
                    "ES",
                    "SE"
                ]
            },
            {
                id: 102,
                name: "Events US",
                domain: "eventsus.primedproxies.com",
                countryCodes: [
                    "US",
                ]
            },
            {
                id: 103,
                name: "Events Asia",
                domain: "eventsasia.primedproxies.com",
                countryCodes: [
                    "CN",
                    "ID",
                    "SG",
                    "HK",
                    "JP",
                ]
            }
        ]
    },
    {
        id: 2,
        group: "DataDome",
        children: [
            {
                id: 200,
                name: "DataDome EU/UK",
                domain: "dd.primedproxies.com",
                countryCodes: [
                    "AT",
                    "BE",
                    "BG",
                    "HR",
                    "CY",
                    "CZ",
                    "DK",
                    "EE",
                    "FI",
                    "FR",
                    "DE",
                    "GR",
                    "HU",
                    "IE",
                    "IT",
                    "LV",
                    "LT",
                    "LU",
                    "MT",
                    "NL",
                    "PL",
                    "PT",
                    "RO",
                    "SK",
                    "SI",
                    "ES",
                    "SE",
                    "GB"
                ]
            },
            {
                id: 201,
                name: "DataDome US",
                domain: "dd.primedproxies.com",
                countryCodes: [
                    "US"
                ]
            }
        ]
    },
    {
        id: 3,
        group: "Custom",
        children: [
            {
                id: 300,
                name: "Random EU",
                domain: "proxy.primedproxies.com",
                countryCodes: [
                    "AT",
                    "BE",
                    "BG",
                    "HR",
                    "CY",
                    "CZ",
                    "DK",
                    "EE",
                    "FI",
                    "FR",
                    "DE",
                    "GR",
                    "HU",
                    "IE",
                    "IT",
                    "LV",
                    "LT",
                    "LU",
                    "MT",
                    "NL",
                    "PL",
                    "PT",
                    "RO",
                    "SK",
                    "SI",
                    "ES",
                    "SE",
                ]
            },
            {
                id: 301,
                name: "Random Worldwide",
                domain: "proxy.primedproxies.com",
                countryCodes: [
                    'af',
                    'al',
                    'dz',
                    'ar',
                    'au',
                    'at',
                    'bs',
                    'bh',
                    'bd',
                    'by',
                    'be',
                    'bo',
                    'br',
                    'bg',
                    'ca',
                    'cl',
                    'co',
                    'cd',
                    'cr',
                    'hr',
                    'cy',
                    'cz',
                    'dk',
                    'ec',
                    'sv',
                    'ee',
                    'fj',
                    'fi',
                    'fr',
                    'pf',
                    'ga',
                    'gm',
                    'ge',
                    'de',
                    'gh',
                    'gi',
                    'gr',
                    'gl',
                    'gd',
                    'gu',
                    'gt',
                    'gn',
                    'gw',
                    'gy',
                    'ht',
                    'hn',
                    'hk',
                    'hu',
                    'is',
                    'in',
                    'id',
                    'ir',
                    'iq',
                    'ie',
                    'im',
                    'il',
                    'it',
                    'ci',
                    'jm',
                    'jp',
                    'je',
                    'jo',
                    'kz',
                    'ke',
                    'xk',
                    'kw',
                    'kg',
                    'lv',
                    'lb',
                    'ls',
                    'lr',
                    'ly',
                    'li',
                    'lt',
                    'lu',
                    'mo',
                    'mk',
                    'mg',
                    'mw',
                    'my',
                    'mv',
                    'kl',
                    'mt',
                    'mh',
                    'mr',
                    'mu',
                    'mx',
                    'md',
                    'mc',
                    'mn',
                    'me',
                    'ma',
                    'mz',
                    'mm',
                    'na',
                    'nr',
                    'np',
                    'nl',
                    'nz',
                    'nc',
                    'ni',
                    'ng',
                    'mp',
                    'no',
                    'om',
                    'pk',
                    'pw',
                    'ps',
                    'pa',
                    'pg',
                    'py',
                    'pe',
                    'ph',
                    'pl',
                    'pt',
                    'pr',
                    'qa',
                    're',
                    'ro',
                    'ru',
                    'rw',
                    'kn',
                    'lc',
                    'vc',
                    'sm',
                    'st',
                    'sa',
                    'sn',
                    'rs',
                    'sl',
                    'sg',
                    'sk',
                    'sl',
                    'so',
                    'za',
                    'kr',
                    'es',
                    'lk',
                    'pm',
                    'sd',
                    'sr',
                    'se',
                    'ch',
                    'sy',
                    'tw',
                    'th',
                    'tg',
                    'tt',
                    'tn',
                    'tr',
                    'tc',
                    'ug',
                    'ua',
                    'ae',
                    'uk',
                    'us',
                    'uy',
                    'uz',
                    've',
                    'vn',
                    'vg',
                    'ye',
                    'zm',
                    'zw'
                ]
            }
        ]
    },
    {
        id: 4,
        group: "Country",
        children: [
            {
                "id": 400,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "af"
                ],
                "name": "Afghanistan"
            },
            {
                "id": 401,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "al"
                ],
                "name": "Albania"
            },
            {
                "id": 402,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "dz"
                ],
                "name": "Algeria"
            },
            {
                "id": 403,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ar"
                ],
                "name": "Argentina"
            },
            {
                "id": 404,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "au"
                ],
                "name": "Australia"
            },
            {
                "id": 405,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "at"
                ],
                "name": "Austria"
            },
            {
                "id": 406,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bs"
                ],
                "name": "Bahamas"
            },
            {
                "id": 407,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bh"
                ],
                "name": "Bahrain"
            },
            {
                "id": 408,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bd"
                ],
                "name": "Bangladesh"
            },
            {
                "id": 409,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "by"
                ],
                "name": "Belarus"
            },
            {
                "id": 410,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "be"
                ],
                "name": "Belgium"
            },
            {
                "id": 411,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bo"
                ],
                "name": "Bolivia"
            },
            {
                "id": 412,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "br"
                ],
                "name": "Brazil"
            },
            {
                "id": 413,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bg"
                ],
                "name": "Bulgaria"
            },
            {
                "id": 414,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ca"
                ],
                "name": "Canada"
            },
            {
                "id": 415,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cl"
                ],
                "name": "Chile"
            },
            {
                "id": 416,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "co"
                ],
                "name": "Colombia"
            },
            {
                "id": 417,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cd"
                ],
                "name": "Democratic Republic of the Congo"
            },
            {
                "id": 418,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cr"
                ],
                "name": "Costa Rica"
            },
            {
                "id": 419,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hr"
                ],
                "name": "Croatia"
            },
            {
                "id": 420,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cy"
                ],
                "name": "Cyprus"
            },
            {
                "id": 421,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cz"
                ],
                "name": "Czech Republic"
            },
            {
                "id": 422,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "dk"
                ],
                "name": "Denmark"
            },
            {
                "id": 423,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ec"
                ],
                "name": "Ecuador"
            },
            {
                "id": 424,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sv"
                ],
                "name": "El Salvador"
            },
            {
                "id": 425,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ee"
                ],
                "name": "Estonia"
            },
            {
                "id": 426,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "fj"
                ],
                "name": "Fiji"
            },
            {
                "id": 427,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "fi"
                ],
                "name": "Finland"
            },
            {
                "id": 428,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "fr"
                ],
                "name": "France"
            },
            {
                "id": 429,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pf"
                ],
                "name": "French Polynesia"
            },
            {
                "id": 430,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ga"
                ],
                "name": "Gabon"
            },
            {
                "id": 431,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gm"
                ],
                "name": "Gambia"
            },
            {
                "id": 432,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ge"
                ],
                "name": "Georgia"
            },
            {
                "id": 433,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "de"
                ],
                "name": "Germany"
            },
            {
                "id": 434,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gh"
                ],
                "name": "Ghana"
            },
            {
                "id": 435,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gi"
                ],
                "name": "Gibraltar"
            },
            {
                "id": 436,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gr"
                ],
                "name": "Greece"
            },
            {
                "id": 437,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gl"
                ],
                "name": "Greenland"
            },
            {
                "id": 438,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gd"
                ],
                "name": "Grenada"
            },
            {
                "id": 439,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 440,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 441,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 442,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 443,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gy"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 444,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ht"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 445,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 446,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 447,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 448,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "is"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 449,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "in"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 450,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "id"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 451,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ir"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 452,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "iq"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 453,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ie"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 454,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "im"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 455,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "il"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 456,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "it"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 457,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ci"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 458,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "jm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 459,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "jp"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 460,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "je"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 461,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "jo"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 462,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 463,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ke"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 464,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "xk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 465,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 466,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 467,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lv"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 468,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lb"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 469,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ls"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 470,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 471,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ly"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 472,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "li"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 473,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 474,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 475,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mo"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 476,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 477,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 478,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 479,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "my"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 480,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mv"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 481,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 482,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 483,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mh"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 484,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 485,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 486,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mx"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 487,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "md"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 488,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 489,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 490,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "me"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 491,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ma"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 492,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 493,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 494,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "na"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 495,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 496,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "np"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 497,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 498,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 499,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 500,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ni"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 501,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ng"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 502,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mp"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 503,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "no"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 504,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "om"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 505,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 506,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 507,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ps"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 508,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pa"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 509,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 510,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "py"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 511,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pe"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 512,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ph"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 513,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 514,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 515,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 516,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "qa"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 517,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "re"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 518,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ro"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 519,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ru"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 520,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "rw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 521,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 522,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 523,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "vc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 524,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 525,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "st"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 526,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sa"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 527,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 528,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "rs"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 529,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 530,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 531,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 532,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 533,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "so"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 534,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "za"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 535,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 536,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "es"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 537,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 538,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 539,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sd"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 540,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 541,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "se"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 542,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ch"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 543,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sy"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 544,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 545,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "th"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 546,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 547,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 548,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 549,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 550,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 551,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ug"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 552,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ua"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 553,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ae"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 554,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gb"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 555,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "us"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 556,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "uy"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 557,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "uz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 558,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ve"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 559,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "vn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 560,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "vg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 561,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ye"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 562,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "zm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 563,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "zw"
                ],
                "name": "Unknown Country"
            }
        ]
    },
]

export default genning;