import "./Footer.scss";

import logo from "../../../assets/images/logo.png";
import products from "../../../data/products";
import { Link } from "react-router-dom";

function Footer() {

    return (
        <>
            <div className="section-7">
                <div className="footer-container">
                    <div className="footer-column">
                        <img className="logo" src={logo} alt="logo" />
                    </div>
                    <div className="footer-column">
                        <span className="footer-title">Primed</span>
                        <Link to="/#">Home</Link>
                        <Link to="/#products">Products</Link>
                        <Link to="/#features">Features</Link>
                        <Link to="/#reviews">Testimonials</Link>
                        <Link to="/#contact">Contact</Link>
                        <a href="https://primed-proxies.gitbook.io/primed-proxies-documentation/" target="_blank">Documentation</a>
                    </div>
                    <div className="footer-column">
                        <span className="footer-title">Products</span>
                        <Link to="/isp">ISP</Link>
                        <Link to="/residential">Residential</Link>
                        <Link to="/servers">Servers</Link>
                    </div>
                    <div className="footer-column">
                        <span className="footer-title">Legal</span>
                        <Link to="/terms-conditions">Terms and Conditions</Link>
                        <Link to="/refund-policy">Refund policy</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                    <div className="footer-column">
                        <span className="footer-title">Social Media</span>
                        <a href="https://discord.gg/primedproxies" target="_blank">Discord</a>
                        <a href="https://twitter.com/PrimedProx" target="_blank">X (Formerly twitter)</a>
                        <a href="https://www.linkedin.com/company/primed-proxies/" target="_blank">LinkedIn</a>
                        <a href="https://t.me/+IlSaXZ4AgagyOWFk" target="_blank">Telegram</a>
                        <a href="https://www.instagram.com/primedproxies" target="_blank">Instagram</a>
                    </div>
                </div>
            </div>
            <div className="section-footer">
                <span className="cc">© {new Date().getFullYear()} Primed Project R Ltd</span>
                <p>
                    <span>Registered at 42-44 Bishopsgate, London, United Kingdom, EC2N 4AH</span>
                    <span className="divider">·</span>
                    <span>Company no. 13043119</span>
                </p>
            </div>
        </>
    );
}

export default Footer;