import { useEffect, useState } from "react";
import Card from "../../../Components/General/Card/Card";
import "./ServersAdmin.scss";
import adminService from "../../../Services/admin.service";
import { useDebouncedCallback } from "use-debounce";
import { useModal } from "../../../Contexts/ModalContext";
import UserModal from "../../../Components/Admin/UserModal/UserModal";

export default function ServersAdmin() {

    const { openModal } = useModal();

    const [searchValue, setSearchValue] = useState("");
    const debounced = useDebouncedCallback((value) => {
        setSearchValue(value);
    }, 500);

    const [lastSearch, setLastSearch] = useState();
    const [data, setData] = useState();
    const [page, setPage] = useState(1);

    useEffect(() => {
        adminService.getServers(page).then(data => setData(data));
    }, []);

    useEffect(() => {
        if (lastSearch || searchValue){
            setPage(1);
            adminService.getServers(1, searchValue).then(data => setData(data));
            setLastSearch(searchValue);
        }
    }, [searchValue]);

    const handlePrevious = () => {
        let newPage = page - 1;
        setPage(newPage);
        adminService.getServers(newPage, searchValue).then(data => setData(data));
    }

    const handleNext = () => {
        let newPage = page + 1;
        setPage(newPage);
        adminService.getServers(newPage, searchValue).then(data => setData(data));
    }

    const showServer = (id) => {
        // openModal(ServerModal, {id: id});
    }

    const serverTypes = [
        "Basic",
        "Advanced"
    ]

    return (
        <div className="dashboard-page table-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">Servers</span>
            </div>
            <Card classes="table-card mt-20">
                <div className="search-container">
                    {data && <div className="server-count-container">
                                <div>Total Servers: {data.totalCount}</div>
                                <div>Available Basic Servers: {data.basicCount}</div>
                                <div>Available Advanced Servers: {data.advancedCount}</div>
                            </div>
                    }
                    <input className="search-input" placeholder="Search..." onChange={(e) => debounced(e.target.value)}></input>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>External Id</th>
                            <th>Server Type</th>
                            <th>IP</th>
                            <th>Reinstalling</th>
                            <th>User id</th>
                            <th/>
                        </tr>
                        {
                            data && data.servers.map(server => (
                                <tr key={server.id}>
                                    <td>{server.id}</td>
                                    <td>{server.externalId}</td>
                                    <td>{serverTypes[server.serverType]}</td>
                                    <td>{server.ip}</td>
                                    <td>{server.reinstalling ? "Yes" : "No"}</td>
                                    <td><span className="clickable" onClick={() => openModal(UserModal, {id: server.userId})}>{server.userId}</span></td>
                                    <td>
                                        <a className="clickable" href={`https://billing.5wire.co.uk/clientarea.php?action=productdetails&id=${server.externalId}`} target="_blank">
                                            view
                                        </a>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="nav-button-container">
                    {page > 1 && <button onClick={handlePrevious}>Previous</button>}
                    {data && page < data.totalPages && <button onClick={handleNext}>Next</button>}
                </div>
            </Card>
        </div>
    )
}