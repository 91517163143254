import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import products from "../../../data/products";
import adminService from "../../../Services/admin.service";
import PopupModal from "../../General/PopupModal/PopupModal";
import "./AddProductToUserModal.scss";

export default function AddProductToUserModal(props) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState(false);


    const getProducts = () => {
        return products.proxies.slice().concat(products.servers.slice()).filter(x => x.id == "86da73c4-47cc-43cd-a291-b61ec25d33d9");
    }

    const onSubmit = (data) => {
        setLoading(true);
        
        adminService.addProductToUser(data).then((result) => {
            setShowSuccess(true);
        }).catch((error) => {
            setError(error.response.data.title);
        }).finally(() => {
            setLoading(false);
        })
    };

    return (
        <PopupModal>
            <div className="add-product-to-user-modal">
                    {showSuccess ? 
                        <div className="modal-header">
                            <h3 className="modal-title">Product added successfully</h3>
                        </div>
                    :
                        <>
                            <div className="modal-header">
                                <h3 className="modal-title">Add Product to User</h3>
                            </div>
                            <div className="modal-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Controller name="accountId" control={control} defaultValue={props.id} render={({ field }) => <input {...field} hidden></input>} />

                                        <div className="form-item w-50">
                                            <label>Product</label>
                                            <Controller
                                                name="productId"
                                                control={control}
                                                defaultValue={getProducts()[0].id}
                                                render={({ field }) => (
                                                    <select {...field} required>
                                                        <option value="" disabled hidden>Select a product</option>
                                                        {
                                                            getProducts().map(product => (
                                                                <option value={product.id}>{product.fullName ?? product.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                )}
                                            />
                                        </div>
                                        <div className="form-item w-50">
                                            <label>GB</label>
                                            <Controller
                                                name="quantity"
                                                control={control}
                                                defaultValue={"1"}
                                                required={true}
                                                render={({ field }) => <input {...field} type="number" min={0} required placeholder='Enter GB' />}
                                            />
                                        </div>
                                        {
                                            error && 
                                                <p className="error">{error}</p>
                                        }
                                        <button id="submit-form" type="submit" disabled={loading}>
                                            {loading && <div className="loader"></div>}
                                            Save
                                        </button>
                                    </form>
                            </div>
                        </>
                    }
            </div>
        </PopupModal>
    )
}